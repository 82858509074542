import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  TextField,
  DialogContent,
  Dialog,
  Divider,
  DialogActions,
  Backdrop,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import * as Yup from "yup";
import { Formik } from "formik";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import Checkbox from '@material-ui/core/Checkbox';
import Interactivecalculator from "../../../blocks/interactivecalculator/src/Interactivecalculator.web";

import { withTranslation } from "react-i18next";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start

  userSchema = () => {
    return Yup.object().shape({
      email: Yup.string().email("Invalid email format").required("Please enter your email"),
      password: Yup.string().required("Please enter your password"),
    });
  };

 
  getErrorMessage = (touched: any, errors: any, value: any) => {
    return this.handleCondition(
      this.handleCondition(touched[value], errors[value], ""),
      <Typography style={{
        color: "var(--Status-Red-600, #DC2626)",
        fontFamily: 'DIN Next LT Arabic Regular',
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "18px",
      }}>
        {errors[value]}
      </Typography>,
      ""
    );
  };

  MainWrapper = styled(Box)(({ theme }) => ({
    "& .grid_left": {
      backgroundColor: "#122A6A",
    },
    "& .grid_right": {
      backgroundColor: "#FFFFFF",
      [theme.breakpoints.down("xs")]: {
        backgroundColor: "#122A6A",
      },
    },
    "& .main_box_left": {
      padding: 0,
      borderRadius: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    "& .box_left": {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    "& .box_left_column_1": {
      display: "flex",
      height: "20%",
    },
    "& .box_left_column_1_logo": {
      height: "146px",
      margin: "50px 0px 0px 19px",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    "& .box_left_column_2_img": {
      display: "flex",
      height: "50%",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        widht: "22%",
      },
    },
    "& .box_left_column_2_img_tag": {
      width: "50%",
      padding: "66px",
      [theme.breakpoints.between("xs", "lg")]: {
        width: "60%",
        padding: "45px",
        height: "auto",
      },
      [theme.breakpoints.down("xs")]: {
        width: "40%",
        padding: "60px",
      },
    },
    "& .box_left_column_3": {
      display: "flex",
      height: "20%",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .box_left_column_3_main_box": {
      height: "160px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "423px",
    },
    "& .box_left_column_3_main_box_text_0":{
      height: "88px",
      [theme.breakpoints.down("xs")]: {
        height: "30px",
        marginTop: "16px",
      },
    },
    "& .box_left_column_3_main_box_text_0_style":{
      fontFamily: "DIN Next LT Arabic Bold",
      fontSize: "31px",
      fontWeight: "700",
      lineHeight: "44px",
      letterSpacing: "-0.01em",
      textAlign: "center",
      color: "#F4F4EA",
      cursor:"pointer",
      borderBottom:"1px solid #F4F4EA",
      [theme.breakpoints.down(767)]: {
        fontSize: "25px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down(370)]: {
        fontSize: "15px",
      },
      "&:hover": {
      },
    },
    "& .box_left_column_3_main_box_text_1": {
      height: "88px",
      [theme.breakpoints.down("xs")]: {
        height: "30px",
        marginTop: "16px",
      },
    },
    "& .box_left_column_3_main_box_text_1_style": {
      fontFamily: "DIN Next LT Arabic Bold",
      fontSize: "31px",
      fontWeight: "700",
      lineHeight: "44px",
      letterSpacing: "-0.01em",
      textAlign: "center",
      color: "#F4F4EA",
      [theme.breakpoints.down(767)]: {
        fontSize: "25px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down(370)]: {
        fontSize: "15px",
      },
    },
    "& .box_left_column_3_main_box_text_2": {
      height: "56px",
      marginTop: "16px",
      padding: "10px",
    },
    "& .box_left_column_3_main_box_text_2_style": {
      fontFamily: "DIN Next LT Arabic Regular",
      fontSize: "17px",
      fontWeight: "500",
      lineHeight: "28px",
      textAlign: "center",
      color: "#DDE0E0",
      [theme.breakpoints.down("xs")]: {
        fontFamily: "DIN Next LT Arabic Regular",
        fontSize: "15px",
        fontWeight: "500",
        lineHeight: "28px",
        textAlign: "center",
        color: "#DDE0E0",
      },
      [theme.breakpoints.down(370)]: {
        fontFamily: "DIN Next LT Arabic Regular",
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "28px",
        textAlign: "center",
        color: "#DDE0E0",
      },
    },
    "& .main_right_box": {
      backgroundColor: "#FFFFFF",
      borderRadius: "24px 24px 0px 0px",
      background: "var(--Basic-White, #FFF)",
    },
    "& .main_login_outer_box": {
        display: 'flex',
        flexDirection: 'column',
        alignitems: 'center',
        margin: "241px 90px",
      
      [theme.breakpoints.down(920)]: {
        margin: "241px 65px",
      },
      [theme.breakpoints.down(820)]: {
        margin: "241px 55px",
      },
      [theme.breakpoints.down(750)]: {
        margin: "241px 45px",
      },
      [theme.breakpoints.down(670)]: {
        margin: "241px 35px",
      },
      [theme.breakpoints.down(655)]: {
        margin: "241px 25px",
      },
      [theme.breakpoints.down("xs")]: {
        margin: "0px 16px",
      },
    },
    "& .login_style_text_1": {
      color: "var(--Neutrals-Cool-gray-900, #0F172A)",
      textAlign: "center",
      fontFamily: "DIN Next LT Arabic Bold",
      fontSize:'30px',
      fontStyle: 'normal',
      fontWeight: "700",
      lineHeight: "40px",
      letterSpacing: "-0.15px",
      [theme.breakpoints.down("xs")]: {
        paddingTop: "16px",
        color: "var(--Primary-Dark-900, #122967)",
        textAlign: "center",
        fontFamily: "DIN Next LT Arabic Bold",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "32px",
        letterSpacing: "-0.12px",
      },
    },
    "& .login_email_text_style": {
      color: "var(--Neutrals-Cool-gray-700, #334155)",
      fontFamily: "DIN Next LT Arabic Bold",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "22px",
      marginTop: "40px",
    },
    "& .login_password_text_style": {
      color: "var(--Neutrals-Cool-gray-700, #334155)",
      fontFamily: "DIN Next LT Arabic Bold",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "22px",
      marginTop: "20px",
    },
    "& .login_outer_box": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "16px",
      marginTop: "32px",
      [theme.breakpoints.down('xs')]:{
        marginTop: "16px",

      }
    },
    "& .login_button": {
      display: "flex",
      width: "100%",
      height: "44px",
      padding: "10px 16px",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      borderRadius: "8px",
      background: "#1B4FE4",
      textTransform: "none",
    },
    "& .login_button_style": {
      color: theme.palette.common.white,
      fontFamily: "DIN Next LT Arabic Bold",
      fontSize:'16px',
      fontStyle: 'normal',
      lineHeight: "24px",
      fontWeight: 700,
   
    },
    "& .login_button_signup_outer_box": {
      display: "flex",
      height: "24px",
      gap: "0px",
      alignItems: "baseline",
      marginTop:'15px',
      [theme.breakpoints.down('xs')]:{
        marginTop:'0px'

      }
    },
    "& .login_back_to_style_text": {
      color: "#1E293B",
      fontFamily: "DIN Next LT Arabic Regular",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      [theme.breakpoints.down(795)]:{
        fontSize: "15px",

      },
      [theme.breakpoints.down(765)]:{
        fontSize: "14px",

      },
      [theme.breakpoints.down(725)]:{
        fontSize: "13px",

      },
      
      [theme.breakpoints.down('xs')]:{
        fontSize: "14px",

      },
      [theme.breakpoints.down(350)]:{
        fontSize: "11px",

      }
    },
    "& .login_button_signup": {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      gap: "8px",
      textTransform: "none",
    },
    "& .login_button_signup_style_text": {
      color: 'var(--Primary-Seconadry-500, #1B4FE4)',
      fontFamily:'DIN Next LT Arabic Bold',
      fontWeight: 700,
      fontStyle: 'normal',
      fontSize: "16px",
      lineHeight: "24px",
      [theme.breakpoints.down(795)]:{
        fontSize: "15px",

      },
      [theme.breakpoints.down(765)]:{
        fontSize: "14px",

      },
      [theme.breakpoints.down(725)]:{
        fontSize: "13px",

      },
      [theme.breakpoints.down('xs')]:{
        fontSize: "14px",

      },
      [theme.breakpoints.down(350)]:{
        fontSize: "11px",

      }
    },
 
    "& .login_error_box": {
      borderRadius: "4px",
      borderLeft: "4px solid var(--Status-Red-600, #DC2626)",
      background: "var(--Status-Red-100, #FEE2E2)",
      display: "flex",
      padding: "12px 16px",
      alignItems: "center",
      gap: "8px",
      marginTop: "40px",
      backgroundColor: "#FEE2E2",
      color: "#DC2626",
    },
    "& .login_error_alert_box": {
      backgroundColor: "#FEE2E2",
      color: "#DC2626",
    },
    "& .login_error_alert_box_style": {
      fontFamily: "DIN Next LT Arabic Regular",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "22px",
      [theme.breakpoints.down(950)]: {
        fontFamily: "DIN Next LT Arabic Regular",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "20px",
      },
      [theme.breakpoints.down(650)]: {
        fontFamily: "DIN Next LT Arabic Regular",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "18px",
      },
      [theme.breakpoints.down('xs')]: {
        fontFamily: "DIN Next LT Arabic Regular",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "22px",
      },
    },
    "& .MuiOutlinedInput-input":{
        padding: '0px 0px !important',
    },
    "& .main_box_right_login_signin_with_button_style": {
      display: 'flex',
      height: '44px',
      padding: '10px 16px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      borderRadius: '8px',
      border: '1px solid var(--Neutrals-Cool-gray-200, #E2E8F0)',
      background:'var(--Basic-White, #FFF)',
      boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
      textTransform:'none'
    },
    "& .main_box_right_login_signin_with_button_text_style": {
      fontFamily: "DIN Next LT Arabic Regular",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "22px",
      textAlign: "left",
      color: "#000000",
    },
    "& .main_box_right_login_check_outer_box": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: 'center',
      marginTop:'-5px',
      [theme.breakpoints.down('xs')]:{
        marginTop:'10px'

      }

    },
    "& .main_box_right_login_check_rem_input_inner_box": {
      display: 'flex',
      alignItems: 'center',
    },
    "& .main_box_right_login_rem_box_text_style": {
      fontFamily: "DIN Next LT Arabic Regular",
      fontSize: "16px",
      fontWeight: "400",
      fontStyle: 'normal',
      lineHeight: "24px",
      textAlign: "left",
      [theme.breakpoints.down(1050)]:{
        fontFamily: "DIN Next LT Arabic Regular",
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "24px",
        fontStyle: 'normal',
        textAlign: "left",
      },
      [theme.breakpoints.down('xs')]:{
        fontFamily: "DIN Next LT Arabic Regular",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        fontStyle: 'normal',
        textAlign: "left",
      }
    },
    "& .main_box_right_login_check_forgot_input_inner_box": {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px'
    },
    "& .displayNone":{
      display:"none"
    },
    "& .main_box_right_login_forgot_box_text_style": {
      fontFamily: "DIN Next LT Arabic Bold",
      fontSize: "16px",
      fontWeight: "700",
      fontStyle: 'normal',
      lineHeight: "24px",
      textAlign: "end",
      color: "#0F172A",
      [theme.breakpoints.down(1050)]:{
        fontFamily: "DIN Next LT Arabic Bold",
        fontSize: "12px",
        fontWeight: "700",
        fontStyle: 'normal',
        lineHeight: "24px",
        textAlign: "end",
        color: "#4338CA",
      },
      [theme.breakpoints.down('xs')]:{
        fontFamily: "DIN Next LT Arabic Bold",
        fontSize: "16px",
        fontWeight: "700",
        fontStyle: 'normal',
        lineHeight: "24px",
        textAlign: "end",
        color: "#4338CA",
      }
    },
    "& .main_box_right_login_or_outer_box_line": {
      width: "100%",
      height: "38px",
      textAlign: "center",
      alignContent: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "0px",
    },
    "& .main_box_right_login_or_inner_box_line": {
      width: "100%",
      height: "0px",
      border: "1px solid",
      borderColor: "#CBD5E1",
    },
    "& .main_box_right_login_or_inner_box": {
      width: "34px",
      height: "38px",
      padding: "1px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      backgroundColor: "#FFFFFF",
    },
    "& .main_box_right_login_or_inner_box_text_style": {
      fontFamily: "DIN Next LT Arabic Regular",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "22px",
      textAlign: "left",
      color: "#64748B",
    },
    "& .root": {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    "& .icon": {
      marginLeft: '-9px',
      border:'1px solid #64748B',
      borderRadius: 6,
      width: 20,
      height: 20,
      [theme.breakpoints.down(776)]:{
        width: 15,
        height: 15,

      },
      [theme.breakpoints.down('xs')]:{
        width: 20,
        height: 20,

      },
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#FFFFFF',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    "& .checkedIcon": {
      backgroundColor: '#1B4FE4',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 20,
        height: 20,
        [theme.breakpoints.down(776)]:{
          width: 15,
          height: 15,
  
        },
        [theme.breakpoints.down('xs')]:{
          width: 20,
          height: 20,
  
        },
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#106ba3',
      },
    },
    
    "& .login_success_alert_box": {
      color: "#51a775",
      backgroundColor: "#C3F3D7",
    },
    "& .login_success_alert_box_style": {
      fontFamily: "DIN Next LT Arabic Regular",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "22px",
      [theme.breakpoints.down(950)]: {
        fontStyle: "normal",
        fontFamily: "DIN Next LT Arabic Regular",
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "20px",
      },
      [theme.breakpoints.down(650)]: {
        fontSize: "10px",
        fontFamily: "DIN Next LT Arabic Regular",
        lineHeight: "18px",
        fontStyle: "normal",
        fontWeight: "400",
      },
      [theme.breakpoints.down('xs')]: {
        fontFamily: "DIN Next LT Arabic Regular",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "22px",
      },
    },
    "& .login_success_box": {
      borderRadius: "4px",
      display: "flex",
      borderLeft: "4px solid var(--Status-Red-600, #51a775)",
      background: "#C3F3D7",
      padding: "12px 16px",
      alignItems: "center",
      marginTop: "40px",
      backgroundColor: "#C3F3D7",
      color: "#51a775",
      gap: "8px",
    },
  
    
  }));
  StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {backgroundColor: 'white', borderRadius: '8px', [theme.breakpoints.down('xs')]:{ borderRadius: '8px 8px 32px 8px'},
     "& .DialogContentHeaderTypo":{
      display: "flex",      flexDirection: "column" as "column",      alignItems: "center",
      justifyContent: "center",      fontSize: "18px",      fontWeight: 700,
      color: "#1E293B",      lineHeight: "18px",      fontFamily: "DIN Next LT Arabic Bold"}, 
     "& .DialogContentTypo":{      display: "flex",
      flexDirection: "column" as "column",      alignItems: "center",      justifyContent: "center",      fontSize: "16px",      fontWeight: 700,
      color: "#1E293B",      lineHeight: "18px",      fontFamily: "DIN Next LT Arabic Regular",      marginTop:'10px'},
     "& .DialogAction":{
      display: "flex",      flexDirection: "column" as "column",
      alignItems: "center",      justifyContent: "center"    },
     "& .DialogActionTypo":{
      fontSize: "16px",
      fontWeight: 700,      color: "blue",
      lineHeight: "18px",
      fontFamily: "DIN Next LT Arabic Regular",      cursor:"pointer"
     },        
    },
  }));
  renderPasswordChangeSuccessMessage = (changePasswordCondition:any,EmailValidationCondition:any)=>{
    return(
      changePasswordCondition && !EmailValidationCondition ? (
    <Box 
    className="login_success_box">
      <Box 
      className="login_success_alert_box">
        <Typography 
        className="login_success_alert_box_style">
        {changePasswordCondition}
        </Typography>
      </Box>
    </Box>
  ) : (
    ""
  ))}
  renderIncorrectCredentialsMessage = (Condition:any)=>{
    return(
      Condition == "Login Failed" ? (
        <Box className="login_error_box">
          <Box className="login_error_alert_box">
            <Typography className="login_error_alert_box_style">Credentials are incorrect please enter correct credentials.
            </Typography>
          </Box>
        </Box>
      ) : (
        ""
      )

    )}
    renderAccountNotFoundMessage = (Condition:any)=>{
      return(
        Condition == "Account not found, or not activated" ? (
          <Box 
          className="login_error_box">
            <Box className="login_error_alert_box">
              <Typography className="login_error_alert_box_style">
                {this.state.errorMessage}
              </Typography>
            </Box>
        </Box>
        ) : (
          ""
        )
  
      )}
 // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <this.MainWrapper>
        <ThemeProvider theme={theme}>
          <Grid container spacing={0}>
          <this.StyledDialog open={this.state.openDialog} onClose={this.handleCloseDialog}  BackdropComponent={Backdrop}
                BackdropProps={{ invisible: true }}>
                <DialogContent className="dialogContent">
                {this.state.showAccountNotActivated &&(<>
                  <Typography className="DialogContentHeaderTypo">Login failed</Typography>
                  <Typography className="DialogContentTypo">Your account is still pending under review</Typography>
                  </> )}
                  {this.state.showSuccessMessage &&(<>
                  <Typography className="DialogContentHeaderTypo">Success</Typography>
                  <Typography className="DialogContentTypo">Your account has been created successfully</Typography>
                  </> )}
                  {this.state.showSuccessMessageProfile &&(<>
                  <Typography className="DialogContentHeaderTypo">Success</Typography>
                  <Typography className="DialogContentTypo">Profile updated successfully</Typography>
                  </> )}
                  {this.state.showSuccessMessageUpdate &&(<>
                  <Typography className="DialogContentHeaderTypo">Updated!</Typography>
                  <Typography className="DialogContentTypo">Need to fill the details to update for Social Account</Typography>
                  </> )}
                  {this.state.showAlreadyRegisterMessage &&(<>
                  <Typography className="DialogContentHeaderTypo">Continue with Login!</Typography>
                  <Typography className="DialogContentTypo">Already registered with same account</Typography>
                  </> )}
                </DialogContent>
                <Divider/>
                <DialogActions className="DialogAction">
                  <Typography className="DialogActionTypo"><Button onClick={this.handleCloseDialog}>Ok</Button></Typography>
                </DialogActions>
            </this.StyledDialog>
            {this.state.openCalculator && (
               <Interactivecalculator
                id="" 
                navigation={this.props.navigation} 
                handleClose={this.handleNavigateToCalculator} 
                i18n={this.props.i18n}>
                </Interactivecalculator>
            )}
            <Grid item xs={12} sm={6} className="grid_left">
              <Box className="main_box_left">
                <Box className="box_left">
                  <Box className="box_left_column_1">
                    <Box className="box_left_column_1_logo">
                      <img alt="Fundsmen" src={this.state.imgLogo} />
                    </Box>
                  </Box>
                  <Box className="box_left_column_2_img">
                    <img
                      className="box_left_column_2_img_tag"
                      alt="Fundsmen"
                      src={this.state.imgLeftLogo}
                    />
                  </Box>
                  <Box className="box_left_column_3">
                    <Box className="box_left_column_3_main_box">
                      <Box className="box_left_column_3_main_box_text_0">
                        <Typography className="box_left_column_3_main_box_text_0_style"
                           onClick={this.handleNavigateToCalculator}
                          >
                          Calculate my investment 
                        </Typography>
                      </Box>
                      <Box className="box_left_column_3_main_box_text_1">
                        <Typography className="box_left_column_3_main_box_text_1_style">
                          Invest your money for easy return
                        </Typography>
                      </Box>
                      <Box className="box_left_column_3_main_box_text_2">
                        <Typography className="box_left_column_3_main_box_text_2_style">
                          "Calculate Your Investment Returns in Real Time and
                          Start Growing Your Wealth Today"
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} className="grid_right">
            <Box className="main_right_box">
                <Box className="main_login_outer_box">
                  <Typography className="login_style_text_1">
                  Sign in
                  </Typography>
                   {this.renderAccountNotFoundMessage(this.state.EmailValidation)}
                   {this.renderIncorrectCredentialsMessage(this.state.EmailValidation)}
                   {this.renderPasswordChangeSuccessMessage(this.state.passwordChangeMsg,this.state.EmailValidation)}
                  <Formik
                    data-test-id="formik"
                    initialValues={{
                      email: this.state.email,
                      password: this.state.password,
                      
                    }}
                    validationSchema={this.userSchema}
                    onSubmit={(values: any) => {
                      this.handleSiginClick(values);
                      
                    }}
                  >
                    {({
                      handleSubmit,
                      errors,
                      values,
                      handleBlur,
                      touched,
                      setFieldValue,
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        noValidate
                        autoComplete="off"
                      >
                        <Typography className="login_email_text_style">
                          Email
                        </Typography>
                        <TextField
                          className="MuiOutlinedInput-input"
                          variant="outlined"
                          name="email"
                          data-test-id="txtInputEmail"
                          placeholder={"Enter your email"}
                          fullWidth
                          onBlur={handleBlur}
                          value={values.email}
                          onChange={(e) =>
                            setFieldValue("email", e.target.value)
                          }
                          style={{
                            width: "100%",
                          }}
                          InputProps={{
                            style: {
                              position: "relative",
                              width: "100%",
                              height: "44px",
                              display: "flex",
                              padding: "10px 8px",
                              alignItems: "center",
                              flexShrink: 0,
                              borderRadius: "8px",
                              background: theme.palette.common.white,
                              marginTop: "6px",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "24px",
                              fontFamily: "DIN Next LT Arabic Regular",
                              border: this.getErrorMessage(touched, errors, 'email') ? '1px solid #F87171' : ''
                            },
                          }}
                        />
                         <Box style={{marginTop:'5px'}}>   {this.getErrorMessage(touched, errors, "email")}</Box>

                        <Typography className="login_password_text_style">
                          Password
                        </Typography>

                        <TextField
                          variant="outlined"
                          type={this.state.enablePasswordField ? "password" : "text"}
                          data-test-id="txtInputPassword"
                          name="password"
                          placeholder={"Enter your password"}
                          fullWidth
                          onBlur={handleBlur}
                          value={values.password}
                          onChange={(e) =>
                            setFieldValue("password", e.target.value)
                          }
                          style={{
                            width: "100%",
                          }}
                          InputProps={{
                            style: {
                              position: "relative", width: "100%",
                              height: "44px",display: "flex",
                              padding: "10px 8px",alignItems: "center",
                              flexShrink: 0,borderRadius: "8px",
                              background: theme.palette.common.white,marginTop: "6px",
                              fontSize: "16px",fontStyle: "normal",
                              fontWeight: 400,lineHeight: "24px",fontFamily: "DIN Next LT Arabic Regular",
                              border: this.getErrorMessage(touched, errors, 'password') ? '1px solid #F87171' : ''
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                               <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                  data-test-id = "show_password_test"
                                  edge="end"
                                  style={{ padding: '6px', margin: '-6px' }} 
                                >
                                  {this.state.enablePasswordField ? (
                                    <VisibilityOff style={{color:'#94A3B8'}}/>
                                  ) : (
                                    <Visibility style={{color:'#94A3B8'}}/>
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                         <Box style={{marginTop:'5px'}}>   {this.getErrorMessage(touched, errors, "password")}</Box>
                      
                        
                         <Box className="main_box_right_login_check_outer_box">
                            <Box className="main_box_right_login_check_rem_input_inner_box">
                              <Checkbox
                                 data-test-id="checkforREmember"
                                 onClick={() =>
                                   this.setRememberMe(!this.state.checkedRememberMe)
                                 }
                                 checked={this.state.checkedRememberMe}
                                className="root"
                                disableRipple
                                color="default"
                                checkedIcon={
                                  <span className={`icon checkedIcon`} />
                                }
                                icon={<span className="icon" />}
                                inputProps={{
                                  "aria-label": "decorative checkbox",
                                }}
                              />
                               <Typography className="main_box_right_login_rem_box_text_style">
                                Remember Me
                              </Typography>
                            </Box>
                          <Box className="main_box_right_login_check_forgot_input_inner_box">
                            
                              <Typography
                              className="main_box_right_login_forgot_box_text_style"
                                style={{ cursor: "pointer" }}
                                data-test-id="handleForgotPasswordClick"
                                onClick={() => this.handleForgotPasswordClick()}
                              >
                                Forgot Password ?
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="login_outer_box">
                          <Button
                            type="submit"
                            className="login_button"
                            data-test-id="btnEmailLogIn"
                          >
                            <Typography className="login_button_style">
                              Sign in
                            </Typography>
                          </Button>
                          <Box className="main_box_right_login_or_outer_box_line">
                    <Box className="main_box_right_login_or_inner_box_line"></Box>
                    <Box className="main_box_right_login_or_inner_box">
                      <Typography className="main_box_right_login_or_inner_box_text_style">
                        OR
                      </Typography>
                    </Box>
                  </Box>
                
                  <GoogleOAuthProvider clientId={this.state.googleClientId}>
                      <Box style={{position:"relative", width:"100%"}}>
                        <Button
                        data-test-id="googleLogin"
                        variant="contained"
                        fullWidth
                        ref={this.state.selectRef}
                        style={{
                          height: '44px',
                          padding: '10px 16px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: '8px',
                          borderRadius: '8px',
                          border: '1px solid var(--Neutrals-Cool-gray-200, #E2E8F0)',
                          background:'#FFFFFF',
                          boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
                          textTransform:'none',

                          fontFamily: "DIN Next LT Arabic Regular",
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "22px",
                          textAlign: "left",
                          color: "#000000",
                          width:"100%"
                        }}
                        >
                          <img alt="google" src={this.state.imgGoogleIcon} />
                          &nbsp;
                          Sign in with Google
                        </Button>
                        <Button style={{
                          left:0,
                          top:0,
                          position:"absolute",
                          width:"100%",
                          opacity:0
                        }}>
                          <GoogleLogin
                          onSuccess={this.handleLoginSuccess}
                          onError={this.handleLoginFailure}
                          useOneTap={true}
                          width={String(this.state.selectWidth)}
                        />
                        </Button>
                      </Box>
                  </GoogleOAuthProvider>
                          <Box className="login_button_signup_outer_box">
                            <Typography className="login_back_to_style_text">
                            Don’t have an account?
                            </Typography>
                            <Button data-test-id="handleSignupClick" style={{ cursor: "pointer" }}
                          onClick={this.handleSignupClick} className="login_button_signup">
                              <Typography className="login_button_signup_style_text">
                                Sign up
                              </Typography>
                            </Button>
                          
                          </Box>
                        </Box>
                      </form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
      </this.MainWrapper>

     // Customizable Area End
    );
  }
}

// Customizable Area Start
 export const EmailAccountLoginBlockWeb = withTranslation()(EmailAccountLoginBlock)
// Customizable Area End